<template>
  <div :class="{ phone: $store.state.isEquipment == 'phone' }">
    <!-- 面包屑 -->
    <div class="mainWidth">
      <el-row type="flex" justify="center" class="crumbs" >
        <el-col>
          <el-breadcrumb separator=">">
            <el-breadcrumb-item to="/" v-if="$route.path !== '/privateDetails'">社科视频首页</el-breadcrumb-item>
            <el-breadcrumb-item to="/zhibo" :class="{ review: $route.query.mian === 'review' }">直播
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="mianroutwo">{{
              miantwo
            }}</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>

      <div class="container" >
        <el-row type="flex" justify="center">
          <el-col>
            <div class="content">
              <template>
                <div v-if="childrenVideos.length">
                  <div v-if="$route.query.mian == 'nzhi'" class="fangtan">
                    <el-row :gutter="$store.state.isEquipment == 'pc' ? 20 : 0" class="container">
                      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" v-for="(item,index) in childrenVideos" :key="item.index">
                        <router-link target="_blank" :to="{
                          path: '/xq',
                          query: {
                            qing: $route.query.qing,
                            mian: 'nzhi',
                            i:index
                          },
                        }">
                          <div class="card">
                            <div class="coverWrap">
                              <img v-lazy="item.coverPath" alt="" class="cover" />
                              <div class="duration">
                                <p>{{ item.TIME_LENGTH }}</p>
                              </div>
                              <img src="@/assets/image/zixun/播放.png" alt="" class="pause" />
                            </div>
                            <div class="card_content">
                              <p class="title">{{ item.SYS_TOPIC }}</p>
                              <p class="time">
                                {{ $ModuleDate(item.RECORD_TIME) }}
                              </p>
                            </div>
                          </div>
                        </router-link>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </template>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/index";
export default {
  name: "",

  data() {
    return {
      twoshow: false,
      mianroutwo: "",
      miantwo: "",
      navshow: true,
      mianone: "视频组",
      menid: "",
      libid: "",
      childrenVideos: [],//视频组中的视频列表
    };
  },
  created() {
    console.log(this.$route);
    // 详情页
    api
      .post(
        `v2/spc/detail/detailByPubId.do?pubId=${this.$route.query.qing}&siteId=2`
      )
      .then((res) => {
        this.miantwo = res.data.resourceDetail.docName; //标题
        this.libid = res.data.resourceDetail.SYS_DOCLIBID;
        this.menid = res.data.resourceDetail.SYS_DOCUMENTID
        this.getGroupMembers();
      });
  },
  methods: {
    getGroupMembers() {
      api.get(
        `pub/docs/${this.libid}/${this.menid}/getGroupMembers`
      )
        .then((res) => {
          this.childrenVideos = res;
        });
    }
  },
};
</script>

<style lang='scss' scoped>
.Details {
  background: #f9f9f9;
  padding-bottom: 32px;

  .crumbs {
    padding-top: 20px;
    color: #999999;
  }

  .container {
    .content {
      margin-top: 20px;
      background: #ffffff;
      padding: 44px 48px 16px 48px;

      .video_play {
        margin-bottom: 32px;
        // width: 100%;

        .title {
          text-align: center;

          p {
            font-size: 38px;
            font-weight: bold;
            color: #212121;
            line-height: 56px;
          }
        }

        .source {
          // line-height: 35.008px;
          // border-bottom: .063rem solid #cad5e2;
          margin-top: 32px;

          p {
            margin-right: 11.2px;
            color: #212121;
            line-height: 40px;
            margin-top: 8px;
            display: inline-block;
            height: 40px;
          }

          // p {
          //   margin-right: 11.2px;
          //   color: #212121;
          //   line-height: 40px;
          //   margin-top: 8px;
          //   display: inline-block;
          //   height: 40px;
          // }

          .share {
            // float: right;
            display: inline-block;
            width: 32px;
            height: 32px;
            cursor: pointer;
            margin-right: 11.2px;
          }

          .xiner:hover .erweima {
            display: block;
          }

          .xiner {
            position: relative;

            .erweima {
              z-index: 10;

              span {
                font-style: normal;
                margin: 0;
                padding: 0;
                font-size: 12px;
              }

              padding: 10px 10px 0.063rem 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              // align-content: center;
              // img{
              //   width: 100%!important;
              //   height: 100%!important;
              // }
              // canvas{
              //   height: 100%;
              //   height: 100%;
              // }
              position: absolute;
              left: -42px;
              top: 50px;
              width: 100px;
              height: 130px;
              background-color: red;
              display: none;
              border: 0.063rem solid #ccc;
              text-align: center;
              background: #fff;
              border-radius: 0.313rem;
            }
          }

          .cut_rule {
            width: 100%;
            border: 0.063rem solid #cad5e2;
          }
        }

        .play {
          width: 75%;
          margin: 32px auto 32px auto;
          position: relative;
          box-shadow: 0.313rem 0.313rem 0.5rem #888888;

          img {
            width: 15%;
            // height: 15%;
            position: absolute;
            bottom: 3%;
            left: 2%;
            cursor: pointer;
            opacity: 0.7;
          }
        }

        .introduce {
          margin-top: 48px;

          p {
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            line-height: 36px;
            text-indent: 2em;
            text-align: justify;
          }
        }
      }
    }
  }

  .review {
    background-color: #2181b0;
    line-height: 30px;
    padding: 0 10px;

    /deep/ .el-breadcrumb__inner {
      color: #fff;
    }
  }
}

.phone {
  .crumbs {
    padding-left: 1rem;
  }
  .container{
    margin:0 0.5rem;
  }
}

</style>
<style lang="scss" scoped>
.el-breadcrumb__inner.is-link {
  color: #999999;
}

.vjs-custom-skin>.video-js .vjs-big-play-button {
  top: 79%;
  left: 40%;
  width: 4.375rem !important;
  height: 4.375rem !important;
  border-radius: 50% !important;
  margin-left: -6.5em !important;
  margin-top: -1em;
  // display: none;
  visibility: hidden;
}

.vjs-icon-placeholder:before {
  position: absolute;
  top: -0.063rem;
  left: 0.063rem;
  width: 100%;
  height: 100%;
}

.vjs-control-bar {
  // display: none!important;
}

.card {
  margin-top: 2rem;
  background: #ffffff;
  box-shadow: 0px 0px 13px 0px rgba(183, 183, 183, 0.38);
  position: relative;
  cursor: pointer;

  .cover {
    width: 100%;
    height: 340px;
  }

  .duration {
    position: absolute;
    bottom: 0;
    right: 0rem;

    p {
      font-size: 1rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 3rem;
      text-align: right;
      padding-right: 1.25rem;
      white-space: nowrap;
    }
  }

  .coverWrap {
    position: relative;
  }

  .pause {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 4rem;
  }

  .card_content {
    padding: 1rem 1.5rem;

    .title {
      font-size: 1.25rem;
      font-weight: bold;
      color: #212121;
      line-height: 2.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .time {
      font-size: 1rem;
      font-weight: 400;
      color: #666666;
      line-height: 2rem;
    }
  }
}

@media screen and(max-width: 1440px) {
  .fangtan .container .card .cover {
    height: 240px;
  }
}
</style>



