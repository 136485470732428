var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ phone: _vm.$store.state.isEquipment == 'phone' }},[_c('div',{staticClass:"mainWidth"},[_c('el-row',{staticClass:"crumbs",attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('el-breadcrumb',{attrs:{"separator":">"}},[(_vm.$route.path !== '/privateDetails')?_c('el-breadcrumb-item',{attrs:{"to":"/"}},[_vm._v("社科视频首页")]):_vm._e(),_c('el-breadcrumb-item',{class:{ review: _vm.$route.query.mian === 'review' },attrs:{"to":"/zhibo"}},[_vm._v("直播 ")]),_c('el-breadcrumb-item',{attrs:{"to":_vm.mianroutwo}},[_vm._v(_vm._s(_vm.miantwo))])],1)],1)],1),_c('div',{staticClass:"container"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('div',{staticClass:"content"},[[(_vm.childrenVideos.length)?_c('div',[(_vm.$route.query.mian == 'nzhi')?_c('div',{staticClass:"fangtan"},[_c('el-row',{staticClass:"container",attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 20 : 0}},_vm._l((_vm.childrenVideos),function(item,index){return _c('el-col',{key:item.index,attrs:{"xs":24,"sm":8,"md":8,"lg":8,"xl":8}},[_c('router-link',{attrs:{"target":"_blank","to":{
                        path: '/xq',
                        query: {
                          qing: _vm.$route.query.qing,
                          mian: 'nzhi',
                          i:index
                        },
                      }}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"coverWrap"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.coverPath),expression:"item.coverPath"}],staticClass:"cover",attrs:{"alt":""}}),_c('div',{staticClass:"duration"},[_c('p',[_vm._v(_vm._s(item.TIME_LENGTH))])]),_c('img',{staticClass:"pause",attrs:{"src":require("@/assets/image/zixun/播放.png"),"alt":""}})]),_c('div',{staticClass:"card_content"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.SYS_TOPIC))]),_c('p',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.$ModuleDate(item.RECORD_TIME))+" ")])])])])],1)}),1)],1):_vm._e()]):_vm._e()]],2)])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }